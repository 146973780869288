// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-size: 12px;
}

/*
Para cuando necesitemos header en juegos.
igual da problemas ha solucionar que se pierde la standardBar
app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

ion-router-outlet {
  margin-top: 58px;
}
*/
.balance {
  text-transform: uppercase;
  font-weight: 600;
}

ion-button {
  font-size: 16px;
  background-color: #fff;
  border-radius: 5px;
  font-weight: 600;
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;;;;;;;;;;;;;CAAA;AAeA;EACE,yBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AAAF","sourcesContent":["* {\n  font-size: 12px;\n}\n\n/*\nPara cuando necesitemos header en juegos.\nigual da problemas ha solucionar que se pierde la standardBar\napp-header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n}\n\nion-router-outlet {\n  margin-top: 58px;\n}\n*/\n\n.balance {\n  text-transform: uppercase;\n  font-weight: 600;\n}\n\nion-button {\n  font-size: 16px;\n  background-color: #fff;\n  border-radius: 5px;\n  font-weight: 600;\n  margin: 0 10px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
