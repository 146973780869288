import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPageComponent } from './modal-page/modal-page.component';
import { IonicModule } from '@ionic/angular';
import { ClockComponent } from './clock/clock.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { VirtualKeyboardComponent } from './virtual-keyboard/virtual-keyboard.component';
import { Button01Component } from '@design/ui';

@NgModule({
  declarations: [ModalPageComponent, ClockComponent, HeaderComponent, ModalDialogComponent, VirtualKeyboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    Button01Component
    //ModalDialogComponent //descomentando: Uncaught Error: Type ModalDialogComponent does not have 'ɵmod'
  ],
  exports: [
    ModalPageComponent,
    ClockComponent,
    HeaderComponent,
    ModalDialogComponent,
    VirtualKeyboardComponent
  ]
})
export class ComponentsModule { }
