// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#closebtn {
  position: fixed;
  top: 10px;
  right: 15px;
}

.enabledColorStyle {
  color: red;
  cursor: pointer;
}

ion-content.content {
  padding: 15px;
}

ion-textarea {
  font-size: large;
  top: 10px;
}

.content-button {
  display: flex;
  justify-content: space-evenly;
  padding: 40px;
}

ion-button.button-cancel {
  background: crimson !important;
  color: #fff;
  border-radius: 20px;
  font-size: 20px;
  height: 45px;
}

ion-button.button-cancel:hover {
  background: rgb(141, 12, 38) !important;
  color: #fff;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-dialog/modal-dialog.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,SAAA;EACA,WAAA;AAAF;;AAGA;EACE,UAAA;EACA,eAAA;AAAF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,gBAAA;EACA,SAAA;AAFF;;AAOA;EACE,aAAA;EACA,6BAAA;EACA,aAAA;AAJF;;AAOA;EACE,8BAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AAJF;;AAOA;EACE,uCAAA;EACA,WAAA;EACA,mBAAA;AAJF","sourcesContent":["\n#closebtn {\n  position: fixed;\n  top: 10px;\n  right: 15px;\n}\n\n.enabledColorStyle{\n  color: red;\n  cursor: pointer;\n}\n\n//copiado de modal-page.component.scss:\n\nion-content.content {\n  padding: 15px;\n}\n\nion-textarea {\n  font-size: large;\n  top:10px;\n}\n\n// ------- Buttons ---------\n\n.content-button {\n  display: flex;\n  justify-content: space-evenly;\n  padding: 40px;\n}\n\nion-button.button-cancel {\n  background: crimson !important;\n  color: #fff;\n  border-radius: 20px;\n  font-size: 20px;\n  height: 45px;\n}\n\nion-button.button-cancel:hover {\n  background: rgb(141, 12, 38) !important;\n  color: #fff;\n  border-radius: 20px;\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
