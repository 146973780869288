// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  z-index: 9999;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
}

.key {
  padding: 10px;
  margin: 5px;
  background-color: lightgray;
  cursor: pointer;
}

input {
  margin-top: 20px;
  padding: 10px;
  width: 300px;
  font-size: 18px;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.enter-btn {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}`, "",{"version":3,"sources":["webpack://./src/app/components/virtual-keyboard/virtual-keyboard.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,2BAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,0CAAA;EACA,wCAAA;AACF","sourcesContent":[".modal {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 20px;\n  border: 1px solid black;\n  z-index: 9999;\n}\n\n.keyboard {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.row {\n  display: flex;\n}\n\n.key {\n  padding: 10px;\n  margin: 5px;\n  background-color: lightgray;\n  cursor: pointer;\n}\n\ninput {\n  margin-top: 20px;\n  padding: 10px;\n  width: 300px;\n  font-size: 18px;\n}\n\nbutton {\n  margin-top: 10px;\n  padding: 10px 20px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.enter-btn {\n  background-color: var(--ion-color-primary);\n  color: var(--ion-color-primary-contrast);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
